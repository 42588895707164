<template>
  <main>
    <div class="container">
      <form class="form-horizontal mb20" @submit.prevent="onSubmit">
        <div class="row">
          <div class="col-sm-12">
            <div class="panel">
              <div class="panel-heading">
                <h2 class="pageTitle">
                  パスワード再設定<span>new password</span>
                </h2>
              </div>
              <div class="panel-body">
                <template v-if="isValidUrl">
                  <div v-if="errors.error" class="alert alert-danger">
                    <p>{{ errors.error }}</p>
                  </div>
                  <div class="row form-group mg0 input-wrap">
                    <div class="col-sm-3 label-wrapper">
                      <label>
                        新しいパスワード
                      </label>
                    </div>
                    <div class="col-sm-6 h48">
                      <input v-model="password" type="password" class="form-control useicon" autofocus>
                      <div v-if="errors.password" class="help-block with-errors">
                        {{ errors.password }}
                      </div>
                    </div>
                  </div>
                  <div class="row form-group mg0 input-wrap">
                    <div class="col-sm-3 label-wrapper">
                      <label>
                        パスワード再入力
                      </label>
                    </div>
                    <div class="col-sm-6 h48">
                      <input v-model="re_password" type="password" class="form-control useicon">
                      <div v-if="errors.re_password" class="help-block with-errors">
                        {{ errors.re_password }}
                      </div>
                    </div>
                  </div>
                  <ul class="actionVertical clearfix">
                    <li>
                      <button type="submit" :class="['btn', 'btn01', password !== '' && re_password !== '' ? '' : 'disabled']">
                        パスワードを更新
                      </button>
                    </li>
                  </ul>
                </template>
                <template v-else>
                  このURLは無効になりました。
                  <br>
                  再度、登録をお願いします。
                </template>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </main>
</template>

<script>
import messageUtils from '../../shared/mixins/messageUtils'
import { getResetPasswordApi, putResetPasswordApi } from '../../services/api'
import store from '../../store'
import { actions as actionsApp } from '../../store/modules/app'

export default {
  name: 'ResetPassword',
  mixins: [messageUtils],
  data() {
    return {
      password: '',
      re_password: '',
      isValidUrl: true
    }
  },
  async created() {
    await this.checkExistToken()
  },
  mounted() {
    this.clearMessage()
  },
  methods: {
    async onSubmit() {
      await store.dispatch(actionsApp.SET_LOADING, { active: true })
      const res = await putResetPasswordApi({
        password: this.password,
        re_password: this.re_password,
        token: this.$route.params.token
      })
      await store.dispatch(actionsApp.SET_LOADING, { active: false })
      if (res.status === 202) {
        await this.$router.push({
          name: 'ResetPasswordSuccess'
        })
      } else {
        this.updateMessage(res.status, res.data)
      }
    },
    async checkExistToken() {
      await store.dispatch(actionsApp.SET_LOADING, { active: true })
      const res = await getResetPasswordApi({
        token: this.$route.params.token
      })
      await store.dispatch(actionsApp.SET_LOADING, { active: false })
      if (res.status === 200) {
        this.isValidUrl = true
      } else {
        this.isValidUrl = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.label-wrapper {
  text-align: right;
  label {
    margin-top: 15px;
  }
}
.input-wrap {
  height: 75px;
}
.with-errors {
  color: #a94442;
}
@media only screen and (max-width: 767px) {
  .input-wrap {
    height: 100px;
  }
  .label-wrapper {
    text-align: left;
  }
}
</style>
